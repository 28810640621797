import { Login } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import theme from './theme';
import LoginForm from './LoginForm';

const useStyles = makeStyles(({
    main: {
        background:
            `radial-gradient(circle at 50% 14em, ${theme.palette.custom.paper} 0%, ${theme.palette.background.paper} 60%, ${theme.palette.custom.sidebar} 100%)`,
    },
}));

const LoginPage = () => {
    const classes = useStyles();

    return (
        <Login
            classes={classes}
            theme={theme}
        >
            <LoginForm />
        </Login>
    );
};

export default LoginPage;
