import { getResources, MenuItemLink } from 'react-admin';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DefaultIcon from '@material-ui/icons/ViewList';
import logo from './../img/logo.png';
import UserMenu from './UserMenu';

const useStyles = makeStyles({
    menu: {
        height: '97vh',
        position: 'fixed',
        '@media (max-width:599.95px)': {
            height: '82vh',
            position: 'relative',
        }
    }
});

const Logo = () => (
    <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        wrap="nowrap"
    >
        <Box
            component="img"
            src={logo}
            width={38}
            m={2}
        />
        <Typography variant="h6">
            Autoorder
        </Typography>
    </Grid>
);

const MenuItems = () => {
    const resources = useSelector(getResources).filter((r) => {
        return r.name !== 'user' && r.name !== 'settingssupportapps' && (r.hasCreate || r.hasEdit || r.hasList || r.hasShow);
    });

    return (
        <>
            {resources.map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={(resource.options?.label) || resource.name}
                    leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                    sidebarIsOpen={true}
                />
            ))}
        </>
    );
};

const CustomMenu = () => {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            justify="space-between"
            wrap="nowrap"
            className={classes.menu}
        >
            <Grid item>
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                >
                    <Logo />
                    <MenuItems />
                </Grid>
            </Grid>
            <Grid item>
                <UserMenu />
            </Grid>
        </Grid>
    );
};

export default CustomMenu;
