import { Filter, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    input: {
        '& label': {
            display: 'none'
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.custom.paper,
            '& fieldset': {
                borderColor: theme.palette.custom.paper,
                '& legend': {
                    width: 0,
                },
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
            },
        },
    }
}));

const StatusFilter = ({ filter }) => {
    const classes = useStyles();

    return (
        <Filter
            style={{ marginBottom: 7 }}>
            <SelectInput
                variant="outlined"
                classes={classes}
                alwaysOn
                allowEmpty={false}
                source={filter.source}
                label={filter.label}
                initialValue={filter.initialValue}
                choices={filter.choices}
            />
        </Filter>
    );
};

export default StatusFilter;
