import { Create, SimpleForm, minLength, maxLength, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import PageAppbar from './../components/PageAppbar';
import BackButtonToolbar from '../components/BackButtonToolbar';
import CustomTextField from '../components/CustomTextField';

const useStyles = makeStyles({
    container: {
        maxWidth: '500px'
    },
});

const SettingsSupportCreate = (props) => {
    const classes = useStyles();

    const validateCountryCode = [required(), minLength(2), maxLength(2)];

    return (
        <>
            <PageAppbar title="App Settings" />
            <Create
                {...props}
                actions={<BackButtonToolbar />}
                className={classes.container}
            >
                <SimpleForm redirect="list">
                    <CustomTextField source="countryCode" validate={validateCountryCode} />
                </SimpleForm>
            </Create>
        </>
    );
};

export default SettingsSupportCreate;
