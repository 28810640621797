import { TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    input: {
        '& label': {
            marginTop: '-15px',
            marginLeft: '-13px',
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
            color: theme.palette.custom.disabledText,
            backgroundColor: theme.palette.custom.paper,
            '& fieldset': {
                borderColor: theme.palette.custom.paper,
                '& legend': {
                    width: 0,
                },
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
            },
        },
        width: 'auto'
    },
}));

const CustomerShowField = (props) => {
    const classes = useStyles();

    return (
        <TextInput
            {...props}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            className={classes.input}
            disabled
        />
    );
};

export default CustomerShowField;
