import { createTheme } from '@material-ui/core/styles';

const palette = {
    primary: '#586711',
    background: '#1C1C23',
    paper: '#323238',
    sidebar: '#181818',
    white: '#FFF',
    disabledText: 'rgba(255, 255, 255, 0.8)',
    hover: 'rgba(255, 255, 255, 0.08)',
    tableBorder: '#2a2a2a'
};

const tableBorder = `1px solid ${palette.tableBorder}`;

const theme = createTheme({
    palette: {
        primary: {
            main: palette.primary,
        },
        secondary: {
            main: palette.background,
        },
        background: {
            default: palette.background,
            paper: palette.background,
        },
        action: {
            selected: palette.primary,
        },
        custom: {
            paper: palette.paper,
            disabledText: palette.disabledText,
            sidebar: palette.sidebar,
            white: palette.white,
        },
        success: {
            main: palette.primary,
        },
        type: 'dark',
    },
    typography: {
        fontFamily: 'HelveticaNeue',
    },
    overrides: {
        MuiButton: {
            root: {
                '&:hover': {
                    backgroundColor: palette.primary,
                },
            },
            label: {
                textTransform: 'capitalize',
            }
        },
        MuiInputBase: {
            root: {
                backgroundColor: palette.paper,
                borderRadius: 4
            }
        },
        MuiOutlinedInput: {
            root: {
                '& fieldset': {
                    borderColor: palette.paper,
                    '& legend': {
                        width: 0,
                    },
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: palette.primary,
                },
            },
        },
        MuiLink: {
            root: {
                color: palette.white
            },
        },
        MuiList: {
            root: {
                backgroundColor: palette.paper,
            }
        },
        MuiListItemIcon: {
            root: {
                color: null,
            }
        },
        MuiTableCell: {
            root: {
                borderTop: tableBorder,
                borderBottom: tableBorder,
            },
        },
        MuiTextField: {
            root: {
                marginTop: '1em',
                '& label.MuiInputLabel-shrink': {
                    marginTop: '-13px',
                    marginLeft: '0px',
                },
            }
        },
        MuiAccordion: {
            root: {
                '&:before': {
                    backgroundColor: palette.tableBorder,
                }
            },
        },
        RaLoginForm: {
            input: {
                marginTop: '2em',
                '& label.Mui-focused': {
                    marginTop: '-15px',
                    marginLeft: '-1px',
                },
                '& label.MuiFormLabel-filled': {
                    marginTop: '-15px',
                    marginLeft: '-1px',
                },
            }
        },
        RaFormInput: {
            input: {
                marginTop: '1em',
                '& label.Mui-focused': {
                    marginTop: '-15px',
                    marginLeft: '-1px',
                },
                '& label.MuiFormLabel-filled': {
                    marginTop: '-15px',
                    marginLeft: '-1px',
                },
            }
        },
        RaDatagrid: {
            thead: {
                textTransform: 'uppercase',
            },
            headerCell: {
                backgroundColor: palette.sidebar,
                borderTop: tableBorder,
                borderBottom: tableBorder,
            },
            rowCell: {
                borderTop: tableBorder,
                borderBottom: tableBorder,
            },
        },
        RaLayout: {
            appFrame: {
                '@media (min-width:0px)': {
                    marginTop: '1px'
                },
                '@media (max-width:599.95px)': {
                    marginTop: '1px'
                }
            },
            content: {
                backgroundColor: palette.background,
                zIndex: '1'
            }
        },
        RaMenuItemLink: {
            root: {
                paddingLeft: '24px',
            },
            active: {
                color: palette.primary,
            },
            icon: {
                minWidth: '46px'
            }
        },
        RaUserMenu: {
            userButton: {
                '&:hover': {
                    backgroundColor: palette.hover,
                },
                '& .MuiButton-label': {
                    textTransform: 'none',
                }
            }
        },
        RaPaginationActions: {
            currentPageButton: {
                color: palette.primary
            },
            actions: {
                '& button.MuiButton-textPrimary': {
                    color: palette.white
                },
            },
        },
    },
    props: {
        MuiTextField: {
            variant: 'outlined'
        },
    },
    sidebar: {
        closedWidth: 69,
    },
});

export default theme;
