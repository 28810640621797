import { TopToolbar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import BackButton from './BackButton';

const useStyles = makeStyles({
    toolbar: {
        justifyContent: 'space-between',
        paddingLeft: '17px',
        paddingRight: '17px'
    },
});

const BackButtonToolbar = () => {
    const classes = useStyles();

    return (
        <TopToolbar
            className={classes.toolbar}
        >
            <BackButton />
        </TopToolbar>
    );
};

export default BackButtonToolbar;
