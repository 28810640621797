import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SwaggerUI from 'swagger-ui-react';
import PageAppbar from './../components/PageAppbar';
import 'swagger-ui-react/swagger-ui.css';
import './swagger.css';

const requestInterceptor = (params) => {
    if (params.url.includes('/management/')) {
        const auth = localStorage.getItem('auth');
        if (auth) {
            const { token } = JSON.parse(auth);
            params.headers['Authorization'] = `Bearer ${token}`;
        }
    }

    if (params.headers['Authorization.']) {
        params.headers['Authorization'] = params.headers['Authorization.'];
        delete params.headers['Authorization.'];
    }

    return params;
};

const Swagger = () => (
    <>
        <PageAppbar title="API" />
        <Card >
            <CardContent>
                <SwaggerUI url="/management/swagger" requestInterceptor={requestInterceptor} />
            </CardContent>
        </Card>
    </>
);

export default Swagger;
