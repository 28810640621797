import { cloneElement } from 'react';
import { useEditController, EditContextProvider } from 'react-admin';

const CustomEdit = (props) => {
    const controllerProps = useEditController(props);

    const {
        basePath,
        record,
        redirect,
        resource,
        save,
        saving,
        version
    } = controllerProps;

    return (
        <EditContextProvider value={controllerProps}>
            <>
                {cloneElement(props.children, {
                    basePath,
                    record,
                    redirect,
                    resource,
                    save,
                    saving,
                    version,
                })}
            </>
        </EditContextProvider>
    );
};

export default CustomEdit;
