import { forwardRef } from 'react';
import { MenuItemLink, UserMenu } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import LogoutButton from './LogoutButton';
import { useProfile } from './Profile';

const useStyles = makeStyles((theme) => ({
    userButton: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.body1.fontSize,
        paddingLeft: '28px',
        width: '100%',
        justifyContent: 'flex-start',
        borderRadius: 0,
        '& .MuiButton-startIcon': {
            '& svg': {
                paddingRight: 14,
                fontSize: theme.typography.h5.fontSize
            }
        }
    },
    item: {
        paddingLeft: '20px',
        minWidth: '145px',
        '& .MuiListItemIcon-root': {
            minWidth: '40px'
        }
    }
}));

const SettingsMenu = forwardRef(({ onClick }, ref) => {
    const classes = useStyles();

    return (
        <MenuItemLink

            className={classes.item}
            ref={ref}
            to="/user"
            primaryText="Settings"
            leftIcon={<SettingsIcon />}
            onClick={onClick}
        />
    );
});

const CustomUserMenu = () => {
    const classes = useStyles();

    const { profileVersion } = useProfile();

    return (
        <UserMenu
            key={profileVersion}
            classes={{ userButton: classes.userButton }}
        >
            <SettingsMenu />
            <LogoutButton />
        </UserMenu>
    );
};

export default CustomUserMenu;
