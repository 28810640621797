import { RefreshButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import NavigationRefresh from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme) => ({
    button: {
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    label: {
        color: theme.palette.primary.contrastText,
        textTransform: 'capitalize',
    },
    largeIcon: {
        color: theme.palette.primary.contrastText,
    },
}));

const RefreshButtonCustom = () => {
    const classes = useStyles();

    return (
        <RefreshButton
            icon={<NavigationRefresh />}
            classes={classes}
            size="large"
        />
    );
};

export default RefreshButtonCustom;
