import { useCallback } from 'react';
import { SimpleForm, SaveButton, Toolbar, PasswordInput, useNotify, useRefresh, useMutation, minLength } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomCreate from '../components/CustomCreate';

const useStyles = makeStyles((theme) => ({
    form: {
        '& .MuiCardContent-root': {
            paddingTop: '0px'
        }
    },
    formToolbar: {
        marginTop: 0,
        backgroundColor: theme.palette.secondary.main,
        minHeight: 0,
        paddingLeft: '16px'
    },
    passwordInput: {
        '& .MuiButtonBase-root': {
            '& svg': {
                fontSize: theme.typography.body1.fontSize
            }
        }
    }
}));

const validatePasswordField = minLength(6);

const validatePasswordChange = (values) => {
    const errors = {};

    if (!values.oldPassword) {
        errors.oldPassword = 'Old password is required';
    }

    if (!values.newPassword) {
        errors.newPassword = 'New password is required';
    }

    if (values.newPassword === values.oldPassword) {
        errors.newPassword = 'New password as same as Old password';
    }

    if (values.confirmNewPassword !== values.newPassword) {
        errors.confirmNewPassword = 'Don\'t match with the New password';
        errors.newPassword = 'Don\'t match with the Confirm password';
    }

    return errors;
};

const FormToolbar = (props) => {
    const classes = useStyles();

    return (
        <Toolbar
            {...props}
            className={classes.formToolbar}
        >
            <SaveButton disabled={props.pristine || props.saving} color="primary" />
        </Toolbar>
    );
};

const PasswordInputCustom = (props) => {
    const classes = useStyles();

    return (
        <PasswordInput
            {...props}
            variant="outlined"
            className={classes.passwordInput}
            required
            validate={validatePasswordField}
        />
    );
};

const WrappedPasswordChangeForm = ({ save, ...rest }) => {
    const classes = useStyles();

    const notify = useNotify();
    const refresh = useRefresh();
    const [mutate] = useMutation();

    const customSave = useCallback(
        async (values) => {
            try {
                await mutate(
                    {
                        type: 'create',
                        resource: 'user/password',
                        payload: { data: values },
                    },
                    {
                        returnPromise: true,
                        onSuccess: () => {
                            notify('Password changed', 'success');
                            refresh();
                        },
                    }
                );
            } catch (err) {
                if (err?.body?.errors) {
                    return err.body.errors;
                }
                else {
                    notify(`Password change error: ${err.message}`, 'error');
                }
            }
        },
        [mutate, notify, refresh],
    );

    return (
        <SimpleForm
            save={customSave}
            {...rest}
            toolbar={<FormToolbar />}
            validate={validatePasswordChange}
            submitOnEnter={false}
            className={classes.form}
        >
            <PasswordInputCustom
                label="Old password"
                source="oldPassword"
            />
            <PasswordInputCustom
                label="New password"
                source="newPassword"
            />
            <PasswordInputCustom
                label="Confirm new password"
                source="confirmNewPassword"
            />
        </SimpleForm>
    );
};

const PasswordChangeForm = () => (
    <CustomCreate
        resource="user/password"
        basePath="/user"
        mutationMode="pessimistic"
    >
        <WrappedPasswordChangeForm />
    </CustomCreate>
);

const Password = () => (
    <>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography >Change password</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <PasswordChangeForm />
            </AccordionDetails>
        </Accordion>
    </>
);

export default Password;
