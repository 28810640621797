import { forwardRef } from 'react';
import { useLogout } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

const useStyles = makeStyles((theme) => ({
    button: {
        color: theme.palette.text.secondary,
        paddingLeft: '20px'
    },
    icon: {
        marginRight: '16px'
    }
}));

const LogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const handleClick = () => logout();

    const classes = useStyles();

    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
            className={classes.button}
        >
            <ExitIcon
                className={classes.icon}
            />
            Logout
        </MenuItem>
    );
});

export default LogoutButton;
