import { TabbedShowLayout, Tab, Edit, SimpleForm } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PageAppbar from './../components/PageAppbar';
import Title from './../components/Title';
import CustomerShowToolbar from './CustomerShowToolbar';
import CustomerShowField from './CustomerShowField';
import OrdersList from './OrdersList';
import NestingList from './NestingList';
import StockList from './StockList';

const useStyles = makeStyles({
    container: {
        maxWidth: '1600px'
    },
});

const TablesTabs = (props) => (
    <TabbedShowLayout>
        <Tab label="Stock">
            <Box maxWidth={800}>
                <StockList id={props.id} />
            </Box>
        </Tab>
        <Tab label="Nesting">
            <Box maxWidth={800}>
                <NestingList id={props.id} />
            </Box>
        </Tab>
        <Tab label="Orders">
            <Box maxWidth={800}>
                <OrdersList id={props.id} />
            </Box>
        </Tab>
    </TabbedShowLayout>
);

const CustomerShow = (props) => {
    const classes = useStyles();

    return (
        <>
            <PageAppbar {...props} title="Customer" />
            <Edit
                {...props}
                title={<Title title="Customer" />}
                actions={<CustomerShowToolbar />}
                className={classes.container}
            >
                <SimpleForm toolbar={null}>
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <CustomerShowField source="id" label="Customer ID" />
                                <CustomerShowField source="zirkonzahnCustomerId" label="Zirkonzahn ID" />
                                <CustomerShowField source="nestingId" label="Nesting ID" />
                                <CustomerShowField source="minAmountForAutoOrder" label="Min amount for auto order" />
                            </Grid>
                            <Grid item xs>
                                <CustomerShowField source="region" label="Region" />
                                <CustomerShowField source="address.city" label="City" />
                                <CustomerShowField source="address.street" label="Address" />
                                <CustomerShowField source="address.zipcode" label="Zip" />
                            </Grid>
                            <Grid item xs >
                                <CustomerShowField source="firstName" label="First Name" />
                                <CustomerShowField source="lastName" label="Last Name" />
                                <CustomerShowField source="email" label="Email" />
                            </Grid>
                            <Grid item xs>
                                <CustomerShowField source="company" label="Company" />
                                <CustomerShowField source="status" label="Status" />
                                <CustomerShowField source="lastSyncDate" locales="IT" label="Last Sync Date" />
                            </Grid>
                        </Grid>
                    </>
                </SimpleForm>
            </Edit>

            <TablesTabs id={props.id} />
        </>
    );
};

export default CustomerShow;
