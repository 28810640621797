import { Admin, Resource, RouteWithoutLayout } from 'react-admin';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import { Layout, LoginPage } from './layout';
import customers from './customers';
import orders from './orders';
import settingsCustomers from './settingsCustomers';
import settingsSupport from './settingsSupport';
import settingsSupportApps from './settingsSupportApps';
import swagger from './swagger';
import user from './user';
import ChangeEmail from './user/ChangeEmail';

const CustomRoutes = [
    <RouteWithoutLayout exact path="/changeEmail/:token" component={ChangeEmail} />
];

const App = () => (
    <Admin
        title="Auto Order"
        dataProvider={dataProvider}
        authProvider={authProvider}
        customRoutes={CustomRoutes}
        layout={Layout}
        loginPage={LoginPage}
        disableTelemetry
    >
        {permissions => [
            permissions === 'root' ? <Resource name="customers" {...customers} /> : null,
            permissions === 'root' ? <Resource name="nesting/data" /> : null,
            permissions === 'root' ? <Resource name="nesting/invite" /> : null,
            permissions === 'root' ? <Resource name="stock/data" /> : null,
            permissions === 'root' ? <Resource name="orders/radix/customer" /> : null,
            permissions === 'root' ? <Resource name="orders" {...orders} /> : null,
            permissions === 'root' ? <Resource name="api" {...swagger} /> : null,
            permissions === 'root' ? <Resource name="settingscustomers" {...settingsCustomers} /> : null,
            permissions === 'root' ? <Resource name="settingssupport" {...settingsSupport} /> : null,
            permissions === 'root' ? <Resource name="settingssupportapps" {...settingsSupportApps} /> : null,

            permissions === 'admin' ? <Resource name="customers" {...customers} /> : null,
            permissions === 'admin' ? <Resource name="nesting/data" /> : null,
            permissions === 'admin' ? <Resource name="nesting/invite" /> : null,
            permissions === 'admin' ? <Resource name="stock/data" /> : null,
            permissions === 'admin' ? <Resource name="orders/radix/customer" /> : null,
            permissions === 'admin' ? <Resource name="orders" {...orders} /> : null,
            permissions === 'admin' ? <Resource name="settingscustomers" {...settingsCustomers} /> : null,
            permissions === 'admin' ? <Resource name="settingssupport" {...settingsSupport} /> : null,
            permissions === 'admin' ? <Resource name="settingssupportapps" {...settingsSupportApps} /> : null,

            permissions === 'user' ? <Resource name="customers" {...customers} /> : null,
            permissions === 'user' ? <Resource name="nesting/data" /> : null,
            permissions === 'user' ? <Resource name="nesting/invite" /> : null,
            permissions === 'user' ? <Resource name="stock/data" /> : null,
            permissions === 'user' ? <Resource name="orders/radix/customer" /> : null,
            permissions === 'user' ? <Resource name="orders" {...orders} /> : null,
            permissions === 'user' ? <Resource name="settingscustomers" {...settingsCustomers} /> : null,
            permissions === 'user' ? <Resource name="settingssupport" {...settingsSupport} /> : null,
            permissions === 'user' ? <Resource name="settingssupportapps" {...settingsSupportApps} /> : null,

            permissions === 'swagger' ? <Resource name="api" {...swagger} /> : null,

            permissions === 'app' ? <Resource name="settingssupport" {...settingsSupport} /> : null,
            permissions === 'app' ? <Resource name="settingssupportapps" {...settingsSupportApps} /> : null,

            <Resource name="user" {...user} />
        ]}
    </Admin>
);

export default App;
