import { useCallback } from 'react';
import { SimpleForm, SaveButton, Toolbar, TextInput, useNotify, useRefresh, email, useMutation } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomCreate from '../components/CustomCreate';

const useStyles = makeStyles((theme) => ({
    form: {
        '& .MuiCardContent-root': {
            paddingTop: '0px'
        }
    },
    formToolbar: {
        marginTop: 0,
        backgroundColor: theme.palette.secondary.main,
        minHeight: 0,
        paddingLeft: '16px'
    }
}));

const validateEmail = email();

const FormToolbar = (props) => {
    const classes = useStyles();

    return (
        <Toolbar
            {...props}
            className={classes.formToolbar}
        >
            <SaveButton disabled={props.pristine || props.saving} color="primary" />
        </Toolbar>
    );
};

const WrappedEmailChangeForm = ({ save, ...rest }) => {
    const classes = useStyles();

    const notify = useNotify();
    const refresh = useRefresh();
    const [mutate] = useMutation();

    const customSave = useCallback(
        async (values) => {
            try {
                await mutate(
                    {
                        type: 'create',
                        resource: 'user/email',
                        payload: { data: values },
                    },
                    {
                        returnPromise: true,
                        onSuccess: () => {
                            notify('For accept new email please click the activation link we sent to your new email', 'success');
                            refresh();
                        },
                    }
                );
            } catch (err) {
                if (err?.body?.errors) {
                    return err.body.errors;
                }
                else {
                    notify(`Email change error: ${err.message}`, 'error');
                }
            }
        },
        [mutate, notify, refresh],
    );

    return (
        <SimpleForm
            save={customSave}
            {...rest}
            toolbar={<FormToolbar />}
            submitOnEnter={false}
            className={classes.form}
        >
            <TextInput
                label="New email"
                source="email"
                variant="outlined"
                required
                validate={validateEmail}
            />
        </SimpleForm>
    );
};

const EmailChangeForm = () => (
    <CustomCreate
        resource="user/email"
        basePath="/user"
        mutationMode="pessimistic"
    >
        <WrappedEmailChangeForm />
    </CustomCreate>
);

const Email = ({ data: { email } }) => (
    <>
        <Card>
            <CardContent>
                <TextField
                    label="Email"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        readOnly: true,
                    }}
                    value={email}
                />
            </CardContent>
        </Card>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography >Change email</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <EmailChangeForm />
            </AccordionDetails>
        </Accordion>
    </>
);

export default Email;
