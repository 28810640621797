import { Edit, SimpleForm, BooleanInput, Toolbar, DeleteButton, SaveButton } from 'react-admin';
import PageAppbar from './../components/PageAppbar';
import BackButtonToolbar from '../components/BackButtonToolbar';
import CustomTextField from '../components/CustomTextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        maxWidth: '500px'
    },
});

const redirect = (countryCode) => {
    return `/settingssupport/${countryCode}/show`;
};

const FormToolbar = (props) => {
    return (
        <Toolbar {...props}>
            <SaveButton disabled={props.pristine} redirect={() => redirect(props?.record?.countryCode)} />
            <DeleteButton disabled={props?.record?.version === 'default'} redirect={() => redirect(props?.record?.countryCode)} />
        </Toolbar>
    );
};

const SettingsSupportAppsShow = (props) => {
    const classes = useStyles();

    return (
        <>
            <PageAppbar title="App Settings" />
            <Edit
                {...props}
                actions={<BackButtonToolbar />}
                className={classes.container}
            >
                <SimpleForm
                    toolbar={<FormToolbar />}
                // redirect={redirect}
                >
                    <CustomTextField source="countryCode" disabled />
                    <CustomTextField source="version" disabled />
                    <BooleanInput source="education" />
                    <BooleanInput source="shop" />
                    <BooleanInput source="sensor" />
                    <BooleanInput source="scan" />
                    <BooleanInput source="archive" />
                    <BooleanInput source="milling" />
                    <BooleanInput source="support" />
                    <BooleanInput source="furnace" />
                    <BooleanInput source="maintenance" />
                    <BooleanInput source="my_devices" />
                    <BooleanInput source="sensor_info" />
                </SimpleForm>
            </Edit>
        </>
    );
};

export default SettingsSupportAppsShow;
