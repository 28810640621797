import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import SettingsSupportList from './SettingsSupportList';
import SettingsSupportShow from './SettingsSupportShow';
import SettingsSupportCreate from './SettingsSupportCreate';

const resource = {
    list: SettingsSupportList,
    show: SettingsSupportShow,
    create: SettingsSupportCreate,
    icon: PhonelinkSetupIcon,
    options: { label: 'App Settings' }
};

export default resource;
