import { NumberInput, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PageAppbar from './../components/PageAppbar';
import CustomEdit from '../components/CustomEdit';

const FormToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton disabled={props.pristine || props.saving} />
    </Toolbar>
);

const SettingsCustomers = () => (
    <>
        <PageAppbar title="Customers Settings" />
        <Card>
            <CardContent>
                <CustomEdit id={0} resource="settingscustomers" basePath="/settingscustomers" mutationMode="optimistic">
                    <SimpleForm toolbar={<FormToolbar />}>
                        <TextInput
                            variant="outlined"
                            source="testEmail"
                            label="Email for messages"
                        />
                        <NumberInput
                            variant="outlined"
                            source="minAmountForAutoOrder"
                            label="Min amount for make order"
                            min={0}
                            max={10}
                        />
                    </SimpleForm>
                </CustomEdit>
            </CardContent>
        </Card>
    </>
);

export default SettingsCustomers;
