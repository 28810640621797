import { makeStyles } from '@material-ui/core/styles';
import Brightness1Icon from '@material-ui/icons/Brightness1';

const useStyles = makeStyles({
    center: {
        minWidth: '60px',
        textAlign: 'center',
        fontSize: '0.8rem'
    },
});

const PointField = ({ record, source, trueCondition, colors }) => {
    const classes = useStyles();

    return (
        <Brightness1Icon
            className={classes.center}
            style={record[source] === trueCondition ? { color: colors._true } : { color: colors._false }}
        />
    );
};

export default PointField;
