import { TopToolbar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    toolbar: {
        justifyContent: 'space-between',
        paddingLeft: '17px',
        paddingRight: '17px'
    },
});

const TopToolbarCustom = ({ children }) => {
    const classes = useStyles();

    return (
        <TopToolbar className={classes.toolbar}>
            {children}
        </TopToolbar>
    );
};

export default TopToolbarCustom;
