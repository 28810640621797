import { List, Datagrid, TextField } from 'react-admin';
import PageAppbar from './../components/PageAppbar';
import TopToolbarCustom from '../components/TopToolbar';
import CreateButtonCustom from '../components/CreateButton';

const ShowToolbar = (props) => (
    <TopToolbarCustom>
        <CreateButtonCustom resource={props.resource} />
    </TopToolbarCustom>
);

const SettingsSupportList = (props) => (
    <>
        <PageAppbar title="App Settings" />
        <List
            {...props}
            bulkActionButtons={false}
            actions={<ShowToolbar />}
            perPage={1000}
            pagination={false}
        >
            <Datagrid rowClick="show">
                <TextField source="countryCode" label="Country Code" sortable={false} />
                <TextField source="versions" label="Versions" sortable={false} />
            </Datagrid>
        </List>
    </>
);

export default SettingsSupportList;
