import { Toolbar, DeleteButton, Edit, SimpleForm } from 'react-admin';
import PageAppbar from '../components/PageAppbar';
import BackButtonToolbar from '../components/BackButtonToolbar';
import CustomTextField from '../components/CustomTextField';
import SettingsSupportAppsList from '../settingsSupportApps/SettingsSupportAppsList';

const FormToolbar = (props) => {
    return (
        <Toolbar {...props}>
            <DeleteButton disabled={props?.record?.countryCode === 'default'} />
        </Toolbar>
    );
};

const SettingsSupportShow = (props) => (
    <>
        <PageAppbar title="App Settings" />
        <Edit
            {...props}
            actions={<BackButtonToolbar />}
        >
            <SimpleForm
                toolbar={<FormToolbar />}
            >
                <CustomTextField source="countryCode" disabled />
                <SettingsSupportAppsList id={props.id} />
            </SimpleForm>
        </Edit>
    </>
);

export default SettingsSupportShow;
