import { LinearProgress } from 'react-admin';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useRefreshWhenVisible } from 'ra-core';

const useStyles = makeStyles((theme) => ({
    loader: {
        margin: theme.spacing(0),
        width: '100%',
        position: 'absolute',
        zIndex: 130
    }
}));

const LoadingIndicator = (props) => {
    useRefreshWhenVisible();

    const loading = useSelector((state) => state.admin.loading > 0);

    const classes = useStyles();

    return loading ? (
        <LinearProgress
            timeout={0}
            className={classes.loader}
        />
    ) : (
        <></>
    );
};

const AppBar = () => (
    <LoadingIndicator />
);

export default AppBar;
