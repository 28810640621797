import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const EmptyList = ({ message }) => (
    <Box textAlign="center">
        <Typography>
            {message}
        </Typography>
    </Box>
);

export default EmptyList;
