import PageAppbar from './../components/PageAppbar';
import Settings from './Settings';

const User = () => (
    <>
        <PageAppbar title="User settings" />
        <Settings />
    </>
);

export default User;
