import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RefreshButton from './RefreshButton';
import SearchFilter from './SearchFilter';
import StatusFilter from './StatusFilter';

const ListToolbar = (props) => {
    return (
        <Grid container direction="row" justify="space-between">
            <Grid item>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <RefreshButton />
                    </Grid>
                    <Grid item>
                        <SearchFilter />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography>Status</Typography>
                    </Grid>
                    <Grid item>
                        <StatusFilter filter={props.filter} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ListToolbar;
