import SettingsCustomers from './SettingsCustomers';
import SettingsIcon from '@material-ui/icons/Settings';

const resource = {
    list: SettingsCustomers,
    icon: SettingsIcon,
    options: { label: 'Customers Settings' }
};

export default resource;
