import { useGetOne, usePermissions } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Username from './Username';
import Email from './Email';
import Password from './Password';
import ApiToken from './ApiToken';
import Role from './Role';

const Settings = () => {
    const { permissions } = usePermissions();

    const { data, loading, error } = useGetOne('user', 'data');

    if (error || loading) {
        return (<></>);
    }

    return (
        <Grid
            container
            spacing={2}
        >
            <Grid item xs={12}>
                <Username data={data} />
            </Grid>
            <Grid item xs={12}>
                <Email data={data} />
            </Grid>
            <Grid item xs={12}>
                <Password />
            </Grid>
            {permissions === 'root' || permissions === 'app' ?
                (
                    <Grid item xs={12}>
                        <ApiToken data={data} />
                    </Grid>
                ) :
                (<></>)
            }
            <Grid item xs={12}>
                <Role data={data} />
            </Grid>
        </Grid >
    );
};

export default Settings;
