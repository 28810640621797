import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from 'ra-core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import SwapHorizontalCircleRoundedIcon from '@material-ui/icons/SwapHorizontalCircleRounded';

const useStyles = makeStyles((theme) => ({
    menuButtonIconClosed: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(180deg)',
    },
}));

const SidebarButton = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
        const timeout = setTimeout(() => {
            dispatch(toggleSidebar());
        }, 50);
        return () => clearTimeout(timeout);
    };

    return (
        <IconButton
            color="primary"
            onClick={handleClick}
        >
            <SwapHorizontalCircleRoundedIcon classes={{
                root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
            }} />
        </IconButton>
    );
};

export default SidebarButton;
