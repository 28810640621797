import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import SettingsSupportAppsList from './SettingsSupportAppsList';
import SettingsSupportAppsShow from './SettingsSupportAppsShow';
import SettingsSupportAppCreate from './SettingsSupportAppCreate';

const resource = {
    list: SettingsSupportAppsList,
    show: SettingsSupportAppsShow,
    create: SettingsSupportAppCreate,
    icon: PhonelinkSetupIcon,
    options: { label: 'Support Apps Settings' }
};

export default resource;
