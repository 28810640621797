import { ResourceContextProvider, TextField, List, Datagrid, DateField } from 'react-admin';
import Box from '@material-ui/core/Box';
import EmptyList from './../components/EmptyList';

const StockList = ({ id }) => (
    <Box marginTop={2}>
        <ResourceContextProvider value="stock/data">
            <List
                title={<></>}
                basePath="/stock/data"
                filter={{ customerId: id }}
                sort={{ field: 'updateDate', order: 'DESC' }}
                exporter={false}
                perPage={10}
                actions={null}
                bulkActionButtons={false}
                empty={<EmptyList message="No stock data available" />}
            >
                <Datagrid>
                    <DateField source="updateDate" locales="IT" showTime={true} />
                    <TextField source="articleId" label="id" />
                    <TextField source="article" />
                    <TextField source="amount" />
                </Datagrid>
            </List>
        </ResourceContextProvider>
    </Box>
);

export default StockList;
