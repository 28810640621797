import { ResourceContextProvider, TextField, DateField, List, Datagrid, ArrayField } from 'react-admin';
import Box from '@material-ui/core/Box';
import EmptyList from './../components/EmptyList';

const NestingPanel = () => (
    <ArrayField source="data" currentSort={{ field: 'article', order: 'ASC' }}>
        <Datagrid>
            <TextField source="article" />
            <TextField source="amount" />
        </Datagrid>
    </ArrayField>
);

const NestingList = ({ id }) => (
    <Box marginTop={2}>
        <ResourceContextProvider value="nesting/data">
            <List
                title={<></>}
                basePath="/nesting/data"
                filter={{ customerId: id }}
                sort={{ field: 'date', order: 'DESC' }}
                exporter={false}
                perPage={10}
                actions={null}
                bulkActionButtons={false}
                empty={<EmptyList message="No nesting data available" />}
            >
                <Datagrid rowClick="expand" expand={<NestingPanel />}>
                    <DateField source="date" locales="IT" showTime={true} />
                    <TextField source="action" />
                </Datagrid>
            </List>
        </ResourceContextProvider>
    </Box>
);

export default NestingList;
