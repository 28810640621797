import CustomersList from './CustomersList';
import CustomerShow from './CustomerShow';
import PeopleIcon from '@material-ui/icons/People';

const resource = {
    list: CustomersList,
    show: CustomerShow,
    icon: PeopleIcon,
    options: { label: 'Customers' }
};

export default resource;
