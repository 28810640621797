import { List, Datagrid, TextField, ArrayField, DateField } from 'react-admin';
import ListToolbar from './../components/ListToolbar';
import PageAppbar from './../components/PageAppbar';

const OrdersFilter = (props) => {
    const filter = {
        source: 'status',
        label: 'Status',
        initialValue: 'all',
        choices: [
            { id: 'all', name: 'All' },
            { id: 'new', name: 'New' },
            { id: 'accepted', name: 'Accepted' },
            { id: 'canceled', name: 'Canceled' },
        ],
    };

    return (
        <ListToolbar filter={filter} />
    );
};

const OrderPanel = () => (
    <ArrayField source="orderData" currentSort={{ field: 'articleId', order: 'ASC' }}>
        <Datagrid>
            <TextField source="articleId" label="Article ID" />
            <TextField source="article" label="Article Code" />
            <TextField source="amount" label="Amount" />
        </Datagrid>
    </ArrayField>
);

const OrdersList = (props) => (
    <>
        <PageAppbar title="Orders" />
        <List
            {...props}
            bulkActionButtons={false}
            exporter={false}
            actions={false}
            perPage={25}
            sort={{ field: 'updateDate', order: 'DESC' }}
            filters={<OrdersFilter />}
        >
            <Datagrid rowClick="expand" expand={<OrderPanel />}>
                <TextField source="customerId" label="Customer ID" />
                <TextField source="zirkonzahnCustomerId" label="Zirkonzahn ID" />
                <TextField source="status" label="Status" />
                <DateField source="createDate" locales="IT" label="Create Date" showTime={true} />
                <DateField source="updateDate" locales="IT" label="Update Date" showTime={true} />
            </Datagrid>
        </List>
    </>
);

export default OrdersList;
