import { TopToolbar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import BackButton from './../components/BackButton';
import InviteButton from './InviteButton';

const useStyles = makeStyles({
    toolbar: {
        justifyContent: 'space-between',
        paddingLeft: '17px',
        paddingRight: '17px'
    },
});

const CustomerShowToolbar = (props) => {
    const classes = useStyles();

    return (
        <TopToolbar
            className={classes.toolbar}
        >
            <BackButton />
            <InviteButton id={props?.data?.id} />
        </TopToolbar>
    );
};

export default CustomerShowToolbar;
