import { Layout } from 'react-admin';
import theme from './theme';
import Sidebar from './Sidebar';
import Menu from './Menu';
import AppBar from './AppBar';
import { ProfileProvider } from './Profile';

const CustomLayout = (props) => (
    <ProfileProvider>
        <Layout
            {...props}
            theme={theme}
            sidebar={Sidebar}
            menu={Menu}
            appBar={AppBar}
        />
    </ProfileProvider>
);

export default CustomLayout;
