import { Sidebar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        backgroundColor: theme.palette.custom.sidebar,
    },
}));

const CustomSidebar = props => {
    const classes = useStyles();

    return (
        <Sidebar
            {...props}
            classes={classes}
        />
    );
};

export default CustomSidebar;
