import { useRedirect } from 'react-admin';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

const CreateButtonCustom = ({ resource }) => {
    const redirect = useRedirect();

    const handleClick = () => {
        redirect('create', resource);
    };

    return (
        <Button
            onClick={handleClick}
            startIcon={<AddIcon />}
        >
            Create
        </Button>
    );
};

export default CreateButtonCustom;
