import { createContext, useState, useMemo, useContext } from 'react';

const ProfileContext = createContext();

const ProfileProvider = ({ children }) => {
    const [profileVersion, setProfileVersion] = useState(0);
    const context = useMemo(
        () => ({
            profileVersion,
            refreshProfile: () =>
                setProfileVersion((currentVersion) => currentVersion + 1)
        }),
        [profileVersion]
    );

    return (
        <ProfileContext.Provider value={context}>
            {children}
        </ProfileContext.Provider>
    );
};

const useProfile = () => useContext(ProfileContext);

export { ProfileProvider, useProfile };
