import { cloneElement } from 'react';
import { useCreateController, CreateContextProvider } from 'react-admin';

const CustomCreate = (props) => {
    const createControllerProps = useCreateController(props);

    const {
        basePath,
        record,
        redirect,
        resource,
        save,
        saving,
        version,
    } = createControllerProps;

    return (
        <CreateContextProvider value={createControllerProps}>
            <>
                {cloneElement(props.children, {
                    basePath,
                    record,
                    redirect,
                    resource,
                    save,
                    saving,
                    version,
                })}
            </>
        </CreateContextProvider>
    );
};

export default CustomCreate;
