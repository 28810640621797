import Swagger from './Swagger';
import CallMergeIcon from '@material-ui/icons/CallMerge';

const resource = {
    list: Swagger,
    icon: CallMergeIcon,
    options: { label: 'API' }
};

export default resource;
