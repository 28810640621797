import { List, Datagrid, TextField, ResourceContextProvider, CreateButton } from 'react-admin';
import TopToolbarCustom from '../components/TopToolbar';
import PointField from '../components/PointField';

const ShowToolbar = (props) => {
    const key = Object.keys(props.data)[0];

    return (
        <TopToolbarCustom>
            <CreateButton basePath={`/settingssupportapps/create/${props?.data?.[key]?.countryCode}`} />
        </TopToolbarCustom>
    );
};

const SettingsSupportAppsList = ({ id }) => (
    <ResourceContextProvider value="settingssupportapps">
        <List
            title={<></>}
            basePath="/settingssupportapps"
            bulkActionButtons={false}
            actions={<ShowToolbar />}
            perPage={1000}
            pagination={false}
            sort={{ field: 'version', order: 'DESC' }}
            filter={{ id }}
        >
            <Datagrid rowClick="show" size="medium">
                <TextField source="version" label="Version" />
                <PointField source="education" trueCondition={true} colors={{ _true: 'olivedrab', _false: 'gray' }} />
                <PointField source="shop" trueCondition={true} colors={{ _true: 'olivedrab', _false: 'gray' }} />
                <PointField source="sensor" trueCondition={true} colors={{ _true: 'olivedrab', _false: 'gray' }} />
                <PointField source="scan" trueCondition={true} colors={{ _true: 'olivedrab', _false: 'gray' }} />
                <PointField source="archive" trueCondition={true} colors={{ _true: 'olivedrab', _false: 'gray' }} />
                <PointField source="milling" trueCondition={true} colors={{ _true: 'olivedrab', _false: 'gray' }} />
                <PointField source="support" trueCondition={true} colors={{ _true: 'olivedrab', _false: 'gray' }} />
                <PointField source="furnace" trueCondition={true} colors={{ _true: 'olivedrab', _false: 'gray' }} />
                <PointField source="maintenance" trueCondition={true} colors={{ _true: 'olivedrab', _false: 'gray' }} />
                <PointField source="my_devices" trueCondition={true} colors={{ _true: 'olivedrab', _false: 'gray' }} />
                <PointField source="sensor_info" trueCondition={true} colors={{ _true: 'olivedrab', _false: 'gray' }} />
            </Datagrid>
        </List>
    </ResourceContextProvider>
);

export default SettingsSupportAppsList;
