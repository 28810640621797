import { useState, useEffect } from 'react';
import { useDataProvider, useRedirect } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import theme from '../layout/theme';

const useStyles = makeStyles({
    label: {
        marginLeft: '8px'
    }
});

const EmptyPage = ({ children }) => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="sm">
            <br />
            <Card>
                <CardContent>
                    {children}
                </CardContent>
            </Card>
        </Container>
    </ThemeProvider>
);

const PageWithElements = ({ labelText }) => {
    const classes = useStyles();

    const redirect = useRedirect();

    const handleClick = () => {
        redirect('/');
    };

    return (
        <EmptyPage>
            <Typography
                className={classes.label}
            >
                {labelText}
            </Typography>
            <br />
            <Button
                color="primary"
                onClick={handleClick}
            >
                Go to main page
            </Button>
        </EmptyPage>
    );
};

const SuccessPage = () => (
    <PageWithElements labelText="Email changed" />
);

const ErrorPage = () => (
    <PageWithElements labelText="Email change error" />
);

const ChangeEmail = (props) => {
    const dataProvider = useDataProvider();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const { token } = props.match.params;

    useEffect(() => {
        dataProvider.getOne('user/email', { id: token })
            .then(() => {
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    }, [dataProvider, token]);

    if (loading) {
        return (
            <EmptyPage />
        );
    }

    if (error) {
        return (
            <ErrorPage />
        );
    }

    return (
        <SuccessPage />
    );
};

export default ChangeEmail;
