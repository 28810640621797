import OrdersList from './OrdersList';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const resource = {
    list: OrdersList,
    icon: AttachMoneyIcon,
    options: { label: 'Orders' }
};

export default resource;
