const authProvider = {
    login: ({ username, password }) => {
        const request = new Request('/management/auth/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status === 460) {
                    throw new Error('User not found');
                }
                else if (response.status === 461) {
                    throw new Error('Wrong password');
                }
                else if (response.status === 403) {
                    throw new Error('Access denied');
                }
                else if (response.status === 401) {
                    throw new Error('Unauthorized');
                }
                else if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('auth', JSON.stringify(auth));
            })
            .catch((errorMessage) => {
                if (errorMessage) {
                    throw new Error(errorMessage);
                }
                else {
                    throw new Error('Network error');
                }
            });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem('auth') ? Promise.resolve() : Promise.reject(),
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    getIdentity: () => {
        try {
            const { id, fullName, avatar } = JSON.parse(localStorage.getItem('auth'));
            return Promise.resolve({ id, fullName, avatar });
        } catch (err) {
            return Promise.reject(err);
        }
    },
    getPermissions: () => {
        try {
            const { permissions } = JSON.parse(localStorage.getItem('auth'));
            return permissions ? Promise.resolve(permissions) : Promise.reject();
        }
        catch (err) {
            return Promise.reject();
        }
    },
    changeUsername: (username) => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        auth.fullName = username;
        localStorage.setItem('auth', JSON.stringify(auth));
    }
};

export default authProvider;
