import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const BackButton = ({ history: { goBack } }) => (
    <Button
        onClick={goBack}
        startIcon={<ArrowBackIcon />}
    >
        Back
    </Button>
);

export default withRouter(BackButton);
