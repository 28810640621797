import { useMutation, useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const InviteButton = ({ id }) => {
    const [mutate, { error, loading, loaded }] = useMutation();
    const notify = useNotify();

    const handleClick = () => mutate(
        {
            type: 'getOne',
            resource: 'nesting/invite',
            payload: { id: id }
        },
        {
            onSuccess: response => notify(response?.data?.message ?? 'Success !', response?.data?.type ?? 'info'),
            onFailure: error => notify(error?.message ?? 'Error !', 'warning')
        }
    );

    return (
        <Button
            onClick={handleClick}
            startIcon={<MailOutlineIcon />}
            disabled={error || loading || loaded}
        >
            Invite
        </Button>
    );
};

export default InviteButton;
