import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';
import { Field, Form } from 'react-final-form';
import { email, useMutation } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(
    (theme) => ({
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        button: {
            width: '100%',
        },
        icon: {
            marginRight: theme.spacing(1),
        },
        box: {
            paddingBottom: '7px'
        }
    }),
    { name: 'RaLoginForm' }
);

const Input = ({
    meta: { touched, error },
    input: inputProps,
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const LoginForm = (props) => {
    const { redirectTo } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles(props);

    const validate = (values) => {
        const errors = { username: undefined, password: undefined };

        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    const submit = (values) => {
        setLoading(true);
        login(values, redirectTo)
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                            ? 'ra.auth.sign_in_error'
                            : error.message,
                    'warning',
                    {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                    ? error.message
                                    : undefined,
                    }
                );
            });
    };

    return (
        <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
                <>
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    id="username"
                                    name="username"
                                    component={Input}
                                    label={translate('ra.auth.username')}
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    id="password"
                                    name="password"
                                    component={Input}
                                    label={translate('ra.auth.password')}
                                    type="password"
                                    disabled={loading}
                                    autoComplete="current-password"
                                />
                            </div>
                        </div>
                        <CardActions>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={classes.button}
                            >
                                {loading && (
                                    <CircularProgress
                                        className={classes.icon}
                                        size={18}
                                        thickness={2}
                                    />
                                )}
                                {translate('ra.auth.sign_in')}
                            </Button>
                        </CardActions>
                    </form>
                </>
            )}
        />
    );
};

const ForgotPasswordForm = ({ setForgot }) => {
    const [mutate, { loading }] = useMutation();
    const notify = useNotify();
    const classes = useStyles();

    const validate = (values) => {
        const errors = {};

        if (!values.username && !values.email) {
            errors.username = 'Username or email required';
            errors.email = 'Username or email required';
        }

        if (values.email) {
            const validateEmail = email('Wrong email');
            errors.email = validateEmail(values.email)?.message;
        }

        return errors;
    };

    const submit = (values) => mutate(
        {
            type: 'create',
            resource: 'auth/recover',
            payload: { data: values }
        },
        {
            onSuccess: () => {
                notify('Recover requested. Check your email.', 'success');
                setForgot(false);
            },
            onFailure: (error) => notify(error?.message ? `Error: ${error?.message}` : 'Error !', 'warning')
        }
    );

    return (
        <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
                <>
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    id="username"
                                    name="username"
                                    component={Input}
                                    label="Username"
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    id="email"
                                    name="email"
                                    component={Input}
                                    label="Email"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <CardActions>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={classes.button}
                            >
                                {loading && (
                                    <CircularProgress
                                        className={classes.icon}
                                        size={18}
                                        thickness={2}
                                    />
                                )}
                                Recover password or username
                            </Button>
                        </CardActions>
                    </form>
                </>
            )}
        />
    );
};

const MainForm = () => {
    const classes = useStyles();

    const [forgot, setForgot] = useSafeSetState(false);

    const handleClick = () => {
        setForgot(!forgot);
    };

    if (forgot) {
        return (
            <>
                <ForgotPasswordForm setForgot={setForgot} />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.box}
                >
                    <Button
                        color="primary"
                        size="small"
                        onClick={handleClick}
                    >
                        Login
                    </Button>
                </Box>
            </>
        );
    }

    return (
        <>
            <LoginForm />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.box}
            >
                <Button
                    color="primary"
                    size="small"
                    onClick={handleClick}
                >
                    Recover password or username
                </Button>
            </Box>
        </>
    );
};

export default MainForm;
