import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

const Role = ({ data: { role } }) => (
    <Card>
        <CardContent>
            <TextField
                label="Role"
                variant="outlined"
                size="small"
                InputProps={{
                    readOnly: true,
                }}
                value={role}
            />
        </CardContent>
    </Card>
);

export default Role;
