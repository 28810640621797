import { ResourceContextProvider, TextField, DateField, List, Datagrid, ArrayField } from 'react-admin';
import Box from '@material-ui/core/Box';
import EmptyList from './../components/EmptyList';

const OrderPanel = () => (
    <ArrayField source="data" currentSort={{ field: 'ArticleCode', order: 'ASC' }}>
        <Datagrid>
            <TextField source="ArticleId" label="Article ID" />
            <TextField source="ArticleCode" label="Article Code" />
            <TextField source="Quantity" label="Quantity" />
        </Datagrid>
    </ArrayField>
);

const OrdersList = ({ id }) => (
    <Box marginTop={2}>
        <ResourceContextProvider value="orders/radix/customer">
            <List
                title={<></>}
                basePath="/orders/radix/customer"
                filter={{ customerId: id }}
                sort={{ field: 'documentDate', order: 'DESC' }}
                exporter={false}
                perPage={10}
                actions={null}
                bulkActionButtons={false}
                empty={<EmptyList message="No orders data available" />}
            >
                <Datagrid rowClick="expand" expand={<OrderPanel />}>
                    <TextField source="id" />
                    <DateField source="documentDate" locales="IT" />
                </Datagrid>
            </List>
        </ResourceContextProvider>
    </Box>
);

export default OrdersList;
