import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SidebarButton from './SidebarButton';

const useStyles = makeStyles((theme) => ({
    frame: {
        '@media (min-width:0px)': {
            marginLeft: '-28px'
        },
        '@media (max-width:959px)': {
            marginLeft: '-23px',
            marginTop: '8px',
        },
        '@media (max-width:599.95px)': {
            marginLeft: '17px'
        },
    }
}));

const PageAppbar = ({ title, id }) => {
    const classes = useStyles();

    return (
        <Grid container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.frame}
        >
            <Grid item>
                <SidebarButton />
            </Grid>
            <Grid item>
                <Typography variant="h5">
                    {title} {id ? `${id}` : ''}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default PageAppbar;
