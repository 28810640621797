import { Create, SimpleForm, BooleanInput, minLength, maxLength, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import PageAppbar from './../components/PageAppbar';
import BackButtonToolbar from '../components/BackButtonToolbar';
import CustomTextField from '../components/CustomTextField';

const useStyles = makeStyles({
    container: {
        maxWidth: '500px'
    },
});

const redirect = (basePath, id, data) => `/settingssupport/${data.countryCode}/show`;

const SettingsSupportAppCreate = (props) => {
    const classes = useStyles();

    const countryCode = props?.location?.pathname?.split('/')?.[3];

    const validateVersion = [required(), minLength(3), maxLength(15)];

    return (
        <>
            <PageAppbar title="App Settings" />
            <Create
                {...props}
                actions={<BackButtonToolbar />}
                className={classes.container}
            >
                <SimpleForm redirect={redirect}>
                    <CustomTextField source="countryCode" label="Country Code" defaultValue={countryCode} disabled={true} />
                    <CustomTextField source="version" validate={validateVersion} />
                    <BooleanInput source="education" defaultValue={false} />
                    <BooleanInput source="shop" defaultValue={true} />
                    <BooleanInput source="sensor" defaultValue={true} />
                    <BooleanInput source="scan" defaultValue={true} />
                    <BooleanInput source="archive" defaultValue={true} />
                    <BooleanInput source="milling" defaultValue={true} />
                    <BooleanInput source="support" defaultValue={true} />
                    <BooleanInput source="furnace" defaultValue={true} />
                    <BooleanInput source="maintenance" defaultValue={true} />
                    <BooleanInput source="my_devices" defaultValue={true} />
                    <BooleanInput source="sensor_info" defaultValue={true} />
                </SimpleForm>
            </Create>
        </>
    );
};

export default SettingsSupportAppCreate;
