import { Filter, TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    input: {
        '& label.Mui-focused': {
            display: 'none'
        },
        '& label.MuiFormLabel-filled': {
            display: 'none'
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.custom.paper,
            '& fieldset': {
                borderColor: theme.palette.custom.paper,
                '& legend': {
                    width: 0,
                },
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
            },
        },
    }
}));

const SearchFilter = () => {
    const classes = useStyles();

    return (
        <Filter
            style={{ marginBottom: 7 }}
        >
            <TextInput
                variant="outlined"
                className={classes.input}
                label="Search"
                source="searchString"
                alwaysOn
            />
        </Filter>
    );
};

export default SearchFilter;
