import { useCallback } from 'react';
import { SimpleForm, SaveButton, Toolbar, useNotify, useRefresh, useMutation } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomCreate from '../components/CustomCreate';

const useStyles = makeStyles((theme) => ({
    input: {
        width: '290px',
    },
    form: {
        '& .MuiCardContent-root': {
            paddingTop: '0px'
        }
    },
    formToolbar: {
        marginTop: 0,
        backgroundColor: theme.palette.secondary.main,
        minHeight: 0,
        paddingLeft: '16px'
    }
}));

const FormToolbar = (props) => {
    const classes = useStyles();

    return (
        <Toolbar
            {...props}
            className={classes.formToolbar}
        >
            <SaveButton color="primary" label="Generate" />
        </Toolbar>
    );
};

const WrappedApiTokenChangeForm = ({ save, ...rest }) => {
    const classes = useStyles();

    const notify = useNotify();
    const refresh = useRefresh();
    const [mutate] = useMutation();

    const customSave = useCallback(
        async () => {
            try {
                await mutate(
                    {
                        type: 'create',
                        resource: 'user/apitoken',
                        payload: { data: { token: 'new' } },
                    },
                    {
                        returnPromise: true,
                        onSuccess: () => {
                            notify('New token generated', 'success');
                            refresh();
                        },
                    }
                );
            } catch (err) {
                if (err?.body?.errors?.username) {
                    notify(`${err.body.errors.username}`, 'warning');
                }
                else {
                    notify(`Generate token error: ${err.message}`, 'error');
                }
            }
        },
        [mutate, notify, refresh],
    );

    return (
        <SimpleForm
            save={customSave}
            {...rest}
            toolbar={<FormToolbar />}
            submitOnEnter={false}
            className={classes.form}
        >
        </SimpleForm>
    );
};

const ApiTokenChangeForm = () => (
    <CustomCreate
        resource="user/apitoken"
        basePath="/user"
        mutationMode="pessimistic"
    >
        <WrappedApiTokenChangeForm />
    </CustomCreate>
);

const ApiToken = ({ data: { apiToken } }) => {
    const classes = useStyles();

    return (
        <>
            <Card>
                <CardContent>
                    <TextField
                        className={classes.input}
                        label="Api token"
                        variant="outlined"
                        size="small"
                        InputProps={{
                            readOnly: true,
                        }}
                        value={apiToken}
                    />
                </CardContent>
            </Card>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography >New api token</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ApiTokenChangeForm />
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default ApiToken;
