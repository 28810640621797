import { List, Datagrid, TextField, EmailField, DateField } from 'react-admin';
import ListToolbar from './../components/ListToolbar';
import PageAppbar from './../components/PageAppbar';
import PointField from '../components/PointField';

const CustomersFilter = () => {
    const filter = {
        source: 'status',
        label: 'Status',
        initialValue: 'active',
        choices: [
            { id: 'active', name: 'Active' },
            { id: 'not active', name: 'Not Active' },
            { id: 'all', name: 'All' },
        ],
    };

    return (
        <ListToolbar filter={filter} />
    );
};

const CustomersList = (props) => (
    <>
        <PageAppbar title="Customers" />
        <List
            {...props}
            bulkActionButtons={false}
            exporter={false}
            actions={false}
            perPage={25}
            sort={{ field: 'lastSyncDate', order: 'DESC' }}
            filters={<CustomersFilter />}
        >
            <Datagrid
                size="medium"
                rowClick="show"
            >
                <TextField source="id" label="ID" />
                <TextField source="zirkonzahnCustomerId" label="Zirkonzahn ID" />
                <TextField source="nestingId" label="Nesting ID" />
                <TextField source="region" />
                <TextField source="firstName" />
                <TextField source="lastName" />
                <EmailField source="email" />
                <TextField source="company" />
                <DateField source="lastSyncDate" locales="IT" label="Last Sync Date" />
                <PointField source="status" trueCondition="active" colors={{ _true: 'green', _false: 'red' }} />

            </Datagrid>
        </List>
    </>
);

export default CustomersList;
